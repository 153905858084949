<template>
  <div class="container-fluid">
    <page-tabs page="events" />

    <template v-if="portal && portal.link">
      <div class="row mb-4">
        <div class="col-12">
          <a :href="'https://'+portal.link+'.'+portal.domain+'/waitlists'" class="btn btn-outline-primary" target="_blank"><i class="fas fa-external-link-alt me-2"></i>Open Portal</a>
        </div>
      </div>
    </template>
    <!-- Stats Cards Row -->
    <div class="row g-3 mb-4">
      <div class="col-md-4">
        <div class="card h-100 border-0 shadow-sm">
          <div class="card-body text-center">
            <h3 class="mb-0">{{ total }}</h3>
            <p class="text-muted mb-0">EVENTS</p>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card h-100 border-0 shadow-sm">
          <div class="card-body text-center">
              <h3 class="mb-0">{{ totalCheckins }}</h3>
              <p class="text-muted mb-0">CHECK-INS</p>
            </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card h-100 border-0 shadow-sm">
          <div class="card-body text-center">
            <h3 class="mb-0">{{ avgWaitTime || '15 min' }}</h3>
            <p class="text-muted mb-0">AVG. WAIT TIME</p>
          </div>
        </div>
      </div>
    </div>

    <template v-if="currentLocation && currentLocation.uuid">
      <div class="row mb-4">
        <div class="col-12">
          <button class="btn btn-outline-primary" @click="closeLocation">Close {{ currentLocation.name }}</button>
        </div>
      </div>
    </template>

    <template v-else>
      <!-- Location Selection -->
      <div class="d-flex justify-content-between align-items-center mb-4" v-if="locations && locations.length">
        <template v-if="currentLocation && currentLocation.uuid">
          <button class="btn btn-outline-primary" @click="closeLocation">
            <i class="fas fa-times me-2"></i>
            Close {{ currentLocation.name }}
          </button>
        </template>
        <template v-else>
          <div class="dropdown">
            <button 
              class="btn btn-outline-primary dropdown-toggle" 
              type="button" 
              data-bs-toggle="dropdown" 
              aria-expanded="false"
            >
              <i class="fas fa-map-marker-alt me-2"></i>
              Choose Location
            </button>
            <ul class="dropdown-menu">
              <li v-for="location in locations">
                <button 
                  class="dropdown-item" 
                  type="button"
                  @click="chooseLocation(location)"
                >
                  {{ location.name }}
                </button>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </template>
    
    <div class="row mb-4">
      <div class="col-sm-3">
            <div class="form-group">  
              <label for="date">Event Date</label>
              <input type="date" class="form-control" v-model="queries.from_date" @change="updateWaitlist">
            </div>
      </div><!--Col-->
      <div class="col-sm-3">
          <div class="form-group">
            <label for="status">Status</label>
            <select class="form-control" v-model="queries.status" @change="updateWaitlist">
              <option value="">All</option>
              <option v-for="status in statuses" :value="status.name">{{ status.name }}</option>
            </select>
          </div>
      </div><!--Col-->
      <div class="col-sm-3">
          <div class="form-group">
            <label for="search">Search</label>
            <input type="text" class="form-control" v-model="queries.search" @change="updateWaitlist">
          </div>
      </div><!--Col-->
      <div class="col-sm-3 text-end">
        <button class="btn btn-primary btn-sm mt-4" @click="updateWaitlist" :disabled="processing">Search</button>
      </div>
    </div>

    <div class="row g-4">
      <!-- Scheduled Events List -->
      <div class="col-md-6">
        <div class="card border-0 shadow-sm">
          <div class="card-header bg-white border-0 py-3">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">
                <i class="fas fa-calendar-alt me-2 text-primary"></i>
                {{ events.length }} Events
              </h5>
              <button class="btn btn-sm btn-outline-primary" @click="refreshEvents" :disabled="processing">
                <i class="fas fa-sync-alt"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="list-group list-group-flush">
              <template v-if="events && events.length">
                <div v-for="event in events" class="list-group-item">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <p class="fw-bold mb-1">{{ event.date | date }} - {{ event.time | time }}</p>
                      <template v-if="event.status">
                        <span class="badge rounded-pill bg-primary">{{ setCustomField('events', 'status') }}: {{ event.status }}</span>
                      </template>
                      <template v-if="event.event_reference">
                        <p class="text-muted mb-0">{{ setCustomField('events', 'event_reference') }}: {{ event.event_reference }}</p>
                      </template>
                      <template v-if="event.case_number">
                        <p class="text-muted mb-0">{{ setCustomField('events', 'case_number') }}: {{ event.case_number }}</p>
                      </template>
                      <template v-if="event.description">
                        <p class="text-muted mb-0">{{ setCustomField('events', 'description') }}: {{ event.description }}</p>
                      </template>
                      <template v-if="event.client && event.client.uuid">
                        <p class="text-muted mb-0">{{ setCustomField('clients', 'name') }}: {{ event.client.first_name }} {{ event.client.last_name }}</p>
                        <template v-if="event.client.client_reference">
                          <p class="text-muted mb-0">{{ setCustomField('clients', 'client_reference') }}: {{ event.client.client_reference }}</p>
                        </template>
                        <a :href="'/clients/'+event.client.uuid" class="btn btn-outline-primary btn-sm" target="_blank">
                          <i class="fas fa-user me-1"></i> View Client
                        </a>
                      </template>
                    </div>

                    <div class="btn-group">
                      <a :href="'/events/'+event.uuid" class="btn btn-outline-primary btn-sm" target="_blank">
                        <i class="fas fa-calendar-alt me-1"></i> Event
                      </a>

                    <template v-if="portal && portal.link && event.url">

                      <a :href="'https://'+portal.link+'.'+portal.domain+'/e/'+event.url" class="btn btn-outline-primary btn-sm" target="_blank">
                        <i class="fas fa-external-link-alt me-1"></i> Portal
                      </a>

                      <button class="btn btn-primary btn-sm" @click="genCheckinURL(event)" :disabled="!event.url || processing">
                        <i class="fas fa-qrcode me-1"></i> Check In
                      </button>

                    </template>

                    </div>

                  </div>
                </div>
              </template>
              <div v-else class="list-group-item text-center py-4">
                <p class="text-muted mb-0">No events found</p>
              </div>
            </div>

          </div>

        </div>

              <template v-if="events && events.length && total > events.length">
                <div class="row mt-3">
                  <div class="col-12 text-center">
                    <button
                    type="button"
                    class="btn btn-outline-primary"
                    :disabled="processing"
                    @click="getEvents"
                  >
                    Load more
                  </button>
                </div><!--Col-->
              </div><!--Row-->
            </template>

      </div>

      <!-- Current Check-ins List -->
      <div class="col-md-6">
        <div class="card border-0 shadow-sm">
          <div class="card-header bg-white border-0 py-3">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">
                <i class="fas fa-clipboard-check me-2 text-success"></i>
                {{ checkins.length }} Check-ins
              </h5>
              <button class="btn btn-sm btn-outline-success" @click="refreshCheckins" :disabled="processing">
                <i class="fas fa-sync-alt"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="list-group list-group-flush">
              <template v-if="checkins && checkins.length">
                <div v-for="checkin in checkins" class="list-group-item">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <p class="fw-bold mb-1">{{ checkin.created_at | dateTimezone | timeago }}</p>
                      <p class="text-muted mb-0">{{ checkin.name }}</p>
                      <span :class="getStatusBadgeClass(checkin.status)" class="badge rounded-pill">
                        {{ checkin.status }}
                      </span>
                    </div>

                    <template v-if="checkin.client">
                      <client-button :uuid="checkin.client" />
                    </template>

                    <a :href="'/checkins/'+checkin.uuid" class="btn btn-outline-primary btn-sm" target="_blank"><i class="fas fa-eye me-1"></i> View Check-in</a>
                  </div>
                </div>
              </template>
              <div v-else class="list-group-item text-center py-4">
                <p class="text-muted mb-0">No check-ins found</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {
  mixins: [queries],
  
  data() {
    return {
      events: [],
      checkins: [],
      total: 0,
      totalCheckins: 0,
      processing: false,
      avgWaitTime: '15 min',
      qrCodeUrl: null,
      portal: null,
      locations: [],
      currentLocation: null,
      queries: {},
      statuses: []
    }
  },

  created() {
    this.resetQueries();
    this.setURLParams();
    this.getEvents();
    this.getPortal();
    this.getLocations();
    this.getStatuses();
  },

  mounted() {

  var self = this;

  this.$eventBus.$on('checkins', function(data) {
      
      if(!data.uuid) {
        return;
      }

      self.pushRecord(data);

  });

  },

  methods: {

    resetQueries() {
      this.queries = {
        from_date: null,
        search: null,
        status: null,
        sort: 'date',
        sortDirection: 'asc'
      }
    },

    updateWaitlist() {
      this.events = [];
      this.checkins = [];
      this.getEvents();
      this.getCheckins();
    },

    getStatuses() {
      this.$http.get('/statuses?type=event&slim=true&fields=uuid,name&sort=default&sortDirection=desc')
        .then(response => {
          if (response && response.data) {
            this.statuses = response.data;
          }
        })
    },

    getEvents() {
      this.processing = true;

      if(!this.queries.from_date) {
        this.queries.from_date = this.getCurrentDate();
      }

      if(this.currentLocation && this.currentLocation.uuid) {
        this.queries.location = this.currentLocation.uuid;
      }

      this.updateURL();

      let queries = this.queries;

      queries.skip = this.events.length;

      this.getCheckins();

      this.getTotalEvents();

      this.$http.get(this.buildQueries('/events', queries))
        .then(response => {
          if (response && response.data) {

            if(!this.events.length) {
              this.events = response.data;
            } else if(response.data.length) {
              this.events = this.events.concat(response.data);
            }

            this.processing = false;

            if(!this.events.length) {
              return;
            }

            //sort by time
            this.events.sort((a, b) => {
              return new Date(a.time) - new Date(b.time);
            });

          }
        })


    },

    getTotalEvents() {
      this.$http.get(this.buildQueries('/events/total', this.queries))
        .then(response => {
          if (response && response.status === 200) {
            this.total = response.data;
          }
        })
    },

    getTotalCheckins() {

      var date = this.getCurrentDate();

      this.$http.get('/checkins/total?created_from='+date+' 00:00:00')
        .then(response => {
          if (response && response.status === 200) {
            this.totalCheckins = response.data;
          }
        })
    },

    getCheckins() {

      this.getTotalCheckins();

      var date = this.getCurrentDate();

      this.$http.get('/checkins?sort=created_at&sortDirection=desc&limit=100&created_from='+date+' 00:00:00')
        .then(response => {
          if (response && response.data) {
            this.checkins = response.data;
          }
        })
    },

    getCurrentDate() {
      return new Date().toISOString().split('T')[0]
    },

    checkinEvent(uuid) {
      this.processing = true;
      this.$http.post('/checkins', { event: uuid, status: 'checked-in' })
        .then(response => {
          if (response && response.data) {
            this.$eventBus.$emit('show-alert', {
              text: 'Check-in successful',
              type: 'success'
            });

            //rm event from events array
            this.events = this.events.filter(event => event.uuid !== uuid);

            this.processing = false;
          }
        })
    },

    genCheckinURL(event) {

      var url = 'https://'+this.portal.link+'.'+this.portal.domain+'/checkin?url='+event.url;

      var name = '';

      if(event.client && event.client.first_name) {
        name += event.client.first_name;
      }

      if(event.client && event.client.last_name) {
        name += ' '+event.client.last_name;
      }

      if(name) {
        url += '&name='+name;
      }

      if(event.location) {
        url += '&location='+event.location;
      }

      //open in new tab
      window.open(url, '_blank');
      

    },

    pushRecord(data) {

      console.log(data);

      if(!data.uuid) {
        return;
      }

      if(!this.checkins || this.checkins.length === 0) {
        this.checkins = [];
      }

      //check if the checkin is already in the array then update it if so
      if(this.checkins.find(checkin => checkin.uuid === data.uuid)) {
        this.checkins = this.checkins.map(checkin => checkin.uuid === data.uuid ? data : checkin);
      } else {
          this.checkins.push(data);
      }

      if(!data.event) {
        return;
      }

      if(!this.events || this.events.length === 0) {
        return;
      }

      this.events = this.events.filter(event => event.uuid != data.event);

    },


    getStatusBadgeClass(status) {
      switch (status) {
        case 'checked-in':
          return 'bg-success'
        case 'waiting':
          return 'bg-warning'
        case 'in-progress':
          return 'bg-primary'
        default:
          return 'bg-secondary'
      }
    },

    getPortal() {
      this.$http.get('/portals?limit=1&sort=default&sortDirection=desc&slim=true&fields=uuid,name,link,domain&domain=ecase.io')
        .then(response => {
          if (response && response.data && response.data[0]) {
            this.portal = response.data[0];
          }
        })
    },

    getLocations() {
      this.$http.get('/locations?slim=true&fields=uuid,name&sort=default&sortDirection=desc')
        .then(response => {
          if (response && response.data) {
            this.locations = response.data;
          }
        })
    },

    chooseLocation(location) {
      if(this.currentLocation && this.currentLocation.uuid === location.uuid) {
        this.currentLocation = null;
      } else {
        this.currentLocation = location;
      }

      this.refreshEvents();
      this.refreshCheckins();
    },

    closeLocation() {
      this.currentLocation = null;
      this.refreshEvents();
      this.refreshCheckins();
    },

    refreshEvents() {
      this.events = [];
      this.getEvents();
    },

    refreshCheckins() {
      this.checkins = [];
      this.getCheckins();
    }
  }
}
</script>